import axios from "axios";
import { defineStore } from "pinia";

export const WorkSpaceStore = defineStore("workspace_store", {
    state: () => {
        return {
            spaces: null,
            boards: null,
            items: null,

            workspaces: [],
            active_selections: {},
            active_workspace: null,
            active_board: null,
            active_item: null,
            document_id: null,
            conversation_id: null,
            editor_open: false,
            conversation_open: false,
            is_kanban_view: true,
            user_state: null,
            newWorkspace: {
                space: false,
                board: false,
                item: false,
            },
            newSpace: {
                toggle: false,
                resource: null,
                active: false,
                // BELOW is TO REMOVE SOON - just temporary implementation
                children: {
                    space: false,
                    folder: false,
                    file: false,
                    process: false,
                    link: false,
                    account: false,
                    landing_page: false,
                    invoice: false,
                    form: false,
                    survey: false,
                    customer: false,
                    proposal: false,
                    estimate: false,
                    project: false,
                    job_post: false,
                    payment: false,
                    schedule: false,
                    contract: false,
                    workflow: false,
                },
            },
            isEditingItem: false,
            selectedHoverMenu: null,
            selectedHoverMenuGroup: null,
            selectedHoverMenuPopover: false,
            selectedHoverMenuGroupPopover: false,
            itemChecklist: false,
            itemShowImages: true,
            newWorkspaceResource: null,
            newWorkspaceGroupToggle: false,
            newCategoryCreationEnabled: false,
            selectedSpaceForSaving: null,
            newItemCreation: false,
            isNewItemPopperHidden: false,
            workspaceItemsActiveViewType: "kanban", // kanban, document, spreadsheet, calendar,
            activatedKanban: null,
        };
    },

    getters: {
        getSpaces(state) {
            return state.spaces;
        },
        getBoards(state) {
            return state.boards;
        },
        getItems(state) {
            return state.items;
        },
        getItemActiveViewType(state) {
            return state.workspaceItemsActiveViewType;
        },

        activeWorkspace(state) {
            return state.active_workspace;
        },

        activeBoard(state) {
            return state.active_board;
        },

        activeSelection(state) {
            return {
                ...state.active_selections[state.active_workspace.id],
                active_workspace: this.active_workspace,
            };
        },

        activeSelections(state) {
            return state.active_selections;

            return {
                ...state.active_selections,
                active_workspace: state.active_workspace,
            };
        },

        activePath(state) {
            let active_selection =
                state.active_selections[state.active_workspace?.id];

            return {
                workspace: state.active_workspace,
                board: active_selection?.active_board,
                item: active_selection?.active_item,
                editor_open: state.editor_open,
            };
        },

        hasNewSpace(state) {
            return state.newSpace.toggle;
        },

        hasNewBoard(state) {
            return state.newWorkspace.board;
        },

        hasNewItem(state) {
            return state.newWorkspace.item;
        },

        createSpaceToggle(state) {
            return state.newSpace.active;
            // return state.newSpace.children.space
        },

        getSelectedSpaceForSaving(state) {
            return state.selectedSpaceForSaving;
        },

        getAddedNewSpaceResource(state) {
            return state.newSpace.resource;
        },

        getActivatedKanban(state) {
            return state.activatedKanban;
        },
    },

    actions: {
        setNewWorkspaceGroupToggle(value) {
            this.newWorkspaceGroupToggle = value;
        },

        hideNewItemPopper(value) {
            this.isNewItemPopperHidden = value;
        },

        isNewItemCreated(value) {
            this.newItemCreation = value;
        },

        setSelectedHoverMenu(id) {
            this.selectedHoverMenu = id;
        },

        setSelectedHoverMenuGroup(id) {
            this.selectedHoverMenuGroup = id;
        },

        setSelectedHoverMenuPopover(value) {
            this.selectedHoverMenuPopover = value;
        },

        setSelectedHoverMenuGroupPopover(value) {
            this.selectedHoverMenuGroupPopover = value;
        },

        setWorkspaceItemActiveViewType(type) {
            this.workspaceItemsActiveViewType = type;
        },

        setSelectedSpaceForSaving(space) {
            this.selectedSpaceForSaving = space;
        },

        setWorkspaces(workspaces) {
            this.workspaces = workspaces;
        },

        setActiveWorkspace(workspace) {
            this.active_workspace = workspace;

            if (!this.active_selections[workspace.id]) {
                this.active_selections[workspace.id] = {
                    active_board: null,
                    active_item: null,
                    active_document: null,
                    active_conversation: null,
                    editor_open: false,
                    conversation_open: false,
                    is_kanban_view: true,
                    boards: [],
                    items: [],
                };
            }
            this.active_item = null;

            this.updateUserState();
        },

        setWorkspaceActiveBoard(board) {
            this.active_board = board;

            this.active_selections[this.active_workspace.id].active_board =
                board;
            this.active_item = null;

            this.updateUserState();
        },

        setWorkspaceActiveBoards(boards) {
            this.active_selections[this.active_workspace.id].boards = boards;
        },

        setWorkspaceActiveItem(item) {
            this.active_selections[this.active_workspace.id].active_item = item;
            this.active_selections[this.active_workspace.id].active_document =
                item.document;

            this.active_selections[
                this.active_workspace.id
            ].active_board.active_item = item;
            this.active_selections[
                this.active_workspace.id
            ].active_board.active_item.active_document = item.document;
            this.active_item = item;

            this.updateUserState();
        },

        setWorkspaceActiveItems(items) {
            this.active_selections[
                this.active_workspace.id
            ].active_board.items = items;
        },

        setEditorOpen(is_open) {
            this.editor_open = is_open;
            this.active_selections[this.active_workspace?.id].editor_open =
                is_open;

            this.updateUserState();
        },

        setIsKanbanView(is_kanban_view) {
            this.is_kanban_view = is_kanban_view;
            this.active_selections[this.active_workspace.id].is_kanban_view =
                is_kanban_view;

            this.updateUserState();
        },

        setEditorItem(item) {
            this.editor_item = item;
        },

        setUserState(user_state) {
            console.log("user_state -->", user_state);
            this.user_state = user_state;
        },

        updateUserState() {
            let user_id = localStorage.getItem("kaamfu_user_id");
            let workspace_id = this.activeSelection?.active_workspace?.id;
            let board_id = this.activeSelection?.active_board?.id;
            let item_id = this.activeSelection?.active_item?.id;
            let document_id = this.activeSelection?.active_document?.id;
            let is_document_open = this.activeSelection?.editor_open;
            let is_document_kanban_view = this.activeSelection?.is_kanban_view;
            axios
                .patch(`/user-states/${user_id}`, {
                    workspace_id,
                    board_id,
                    item_id,
                    document_id,
                    is_document_open,
                    is_document_kanban_view,
                })
                .then((res) => {
                    console.log("user state updated");
                });
        },

        newSpaceToggle(isOpen = false) {
            this.newSpace.toggle = isOpen;
        },

        onSpaceAddNewSpace(isOpen = false, resource = {}) {
            this.newSpace.resource = resource;
            this.newSpace.active = isOpen;
            // this.newSpace.children[resource.ref] = isOpen
        },

        toggleItemChecklist(isChecked = false) {
            this.itemChecklist = isChecked;
        },

        toggleShowImages(isChecked = false) {
            this.itemShowImages = isChecked;
        },

        // toggleNewSpace (space) {
        //     this.newWorkspace.space = !this.newWorkspace.space
        //     this.newWorkspaceResource = this.newWorkspace.space ? space : null
        // },

        // toggleNewBoard (board) {
        //     this.newWorkspace.board = !this.newWorkspace.board
        //     this.newWorkspaceResource = this.newWorkspace.board ? board : null
        // },

        // toggleNewItem (item) {
        //     this.newWorkspace.item = !this.newWorkspace.item
        //     this.newWorkspaceResource = this.newWorkspace.item ? item : null
        // },

        setActivatedKanban(args) {
            this.activatedKanban = args;
        },
    },

    persist: true,
});
