import axios from 'axios'
import {defineStore} from 'pinia'
import {useConversation} from "./Conversation";
import DateUtilities from "../Utilities/DateUtilities"

export const useActivity = defineStore('ActivityStore', {
    state: () => ({
        selected_list: '',
        hovered_list: '',
        expanded_list: false,
        profile_ids_conversed_within_selected_days: [],
        filters: {
            profile_id: null,
            space_id: null,
            board_id: null,
            days: null,
            conversation_types: []
        },
        selected_activity: null
    }),
    getters: {},
    actions: {
        setSelectedList(value) {
            if (!value) return

            this.selected_list = value
        },
        setHoveredList(value) {
            if (!value) return

            this.hovered_list = value
        },
        toggleExpand(value = null) {
            if (value === null) {
                return this.expanded_list = !this.expanded_list
            }

            this.expanded_list = value
        },
        setSelectedUserId(val) {
            this.filters.profile_id = val
        },
        setSelectedSpaceId(val) {
            this.filters.space_id = val
        },
        setSelectedBoardId(val) {
            this.filters.board_id = val
        },
        setSelectedDays(val) {
            this.filters.days = val
            this.prepareProfilesConversedWithinSelectedDays();
        },
        setSelectedConversationType(val) {
            this.filters.conversation_types = val
        },
        prepareProfilesConversedWithinSelectedDays() {
            const profiles_conversed_within_selected_days = new Set();

            let conversations = useConversation().handlers;
            if(this.filters.days) {
                 conversations = conversations.filter(conv => {
                    const last_message_date = conv?.getTwilioConversation()?.lastMessage?.dateCreated
                    return last_message_date ? DateUtilities.getDifferenceInDays(last_message_date, new Date()) <= this.filters.days : ''
                });
            }

            const conv_profiles_ids = conversations.filter(conv => !conv.isGroup()).map(conv => conv.getActiveParticipantIds());

            conv_profiles_ids?.forEach(conv => {
                const sort_latest_profile = conv.reverse()
                sort_latest_profile.forEach(user_id => profiles_conversed_within_selected_days.add(user_id))
            })

            this.profile_ids_conversed_within_selected_days = profiles_conversed_within_selected_days
        },
        filterConversations() {
            let conversations = useConversation().handlers
            if (this.filters.space_id)
                conversations = conversations.filter(item => item.space_id === this.filters.space_id)

            if (this.filters.board_id)
                conversations = conversations.filter(item => item.board_id === this.filters.board_id)

            if (this.filters.conversation_types.length > 0)
                conversations = this.filterConversationsByTypes(conversations)

            conversations = conversations.filter(conv => !conv.isFake())

            this.prepareProfilesConversedWithinSelectedDays()

            if (this.filters.profile_id)
                conversations = this.filterConversationsWithUser(conversations)

            return conversations;
        },
        filterConversationsWithLastMessageInDays(conversations) {
            conversations = conversations.filter(item => {
                const last_message_date = item?.getTwilioConversation()?.lastMessage?.dateCreated
                return last_message_date ? DateUtilities.getDifferenceInDays(last_message_date, new Date()) <= this.filters.days : ''
            })
            return conversations;
        },

        filterConversationsWithUser(conversations) {
            return conversations.filter(conv => conv.participants.find(user => user.is_removed == false && user.twilio_identity == this.filters.profile_id))
        },

        filterConversationsByTypes(conversations) {
            return conversations.filter(conversation => {
                if (this.filters.conversation_types.includes('dm') && conversation.isDm())
                    return true;

                if (this.filters.conversation_types.includes('group') && conversation.isGroup())
                    return true;

                if (this.filters.conversation_types.includes('task') && conversation.isItem())
                    return true;

                if (this.filters.conversation_types.includes('board') && conversation.isBoard())
                    return true;

                return false;
            })
        },

        setSelectedActivity(val) {
            // if (val.id === this.selected_activity?.id)
            //     this.selected_activity = null
            // else
            this.selected_activity = val

        }
    }
});
