<template>
    <span
        v-if="props.google"
        v-bind="$attrs"
        class="prospus-g-icon"
        :class="[
            `material-symbols-${props.outlined ? 'outlined' : 'rounded'}`,
            `${props.activeClass}`,
            non_active ? 'text-' + color : '',
            {
                'prospus-g-icon--adjust-left': props.adjustLeft,
                'prospus-g-icon--adjust-top': props.adjustTop,
                'prospus-g-icon--adjust-bottom': props.adjustBottom,
                'text-primary': hovered && !active,
                'text-white': active,
            },
        ]"
        :style="[
            {
                fontSize: size,
                color: props.color,
            },
        ]"
    >
        <slot>
            {{ iconName }}
        </slot>
    </span>
    <v-icon
        v-else
        :class="[
            props.activeClass,
            non_active ? 'text-' + color : '',
            { 'text-primary': hovered && !active },
            { 'text-white': active },
        ]"
        :size="props.size"
    >
        <slot>
            {{ iconName }}
        </slot>
    </v-icon>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    name: {
        type: String,
        default: null,
    },
    google: {
        type: Boolean,
        default: false,
    },
    outlined: {
        type: Boolean,
        default: false,
    },
    color: {
        default: "grey-lighten-1",
    },
    size: {
        type: [String, Number],
        default: null,
    },
    activeClass: {
        type: String,
        default: "font-weight-light",
    },
    hovered: {
        type: Boolean,
        default: false,
    },
    active: {
        type: Boolean,
        default: false,
    },
    adjustLeft: {
        type: Boolean,
        default: true,
    },
    adjustTop: {
        type: Boolean,
        default: false,
    },
    adjustBottom: {
        type: Boolean,
        default: false,
    },
});
const iconMap = {
    default: "mdi-circle",
    account: "mdi-account",
    "account-add": "mdi-account-plus-outline",
    "account-circle": "mdi-account-circle",
    "account-details": "mdi-account-details-outline",
    archive: "mdi-archive-outline",
    download: "mdi-download-outline",
    assign: "mdi-account-multiple-check-outline",
    attachment: "mdi-attachment",
    billing: "mdi-help-circle-outline",
    bubble: "mdi-chart-bubble",
    "calendar-text": "mdi-calendar-text-outline",
    chat: "mdi-message-text-outline",
    "check-outline": "mdi-sticker-check-outline",
    circle: "mdi-circle-medium",
    "circle-checked": "mdi-check-circle",
    "circle-plain": "mdi-check",
    "circle-unchecked": "mdi-circle-outline",
    clipboard: "mdi-clipboard-outline",
    close: "mdi-close-circle-outline",
    "close-plain": "mdi-close",
    copy: "mdi-content-copy",
    delete: "mdi-delete-outline",
    diamond: "mdi-cards-diamond-outline",
    "direct-message": "mdi-message-fast-outline",
    doc: "mdi-file-document-outline",
    down: "mdi-chevron-down",
    drag: "mdi-drag",
    filter: "mdi-filter-variant",
    focus: "mdi-focus-field-horizontal",
    "fold-more": "mdi-unfold-more-horizontal",
    "fold-less": "mdi-unfold-less-horizontal",
    folder: "mdi-folder-outline",
    gamepad: "mdi-gamepad-circle-outline",
    help: "mdi-help-circle-outline",
    invite: "mdi-account-multiple-plus",
    left: "mdi-chevron-left",
    link: "mdi-link",
    logout: "mdi-logout-variant",
    menu: "mdi-menu",
    message: "mdi-message-text-outline",
    mic: "mdi-microphone",
    minus: "mdi-minus",
    none: "mdi-none",
    note: "mdi-note-text-outline",
    pause: "mdi-pause",
    pencil: "mdi-pencil-outline",
    play: "mdi-play",
    plus: "mdi-plus",
    resume: "mdi-play-pause",
    rhombus: "mdi-rhombus-outline",
    right: "mdi-chevron-right",
    search: "mdi-magnify",
    settings: "mdi-account-cog-outline",
    sticker: "mdi-sticker-circle-outline",
    stop: "mdi-stop",
    text: "mdi-text",
    up: "mdi-chevron-up",
    video: "mdi-video-outline",
    comment: "mdi-comment-outline",
    like: "mdi-thumb-up-outline",
    dislike: "mdi-thumb-down-outline",
    replay: "mdi-reply-outline",
    forward: "mdi-share-outline",
    "arrow-left": "mdi-arrow-left",
    history: "mdi-history",
    arrow_left_bold_circle: "mdi-arrow-left-bold-circle",
    arrow_right_bold_circle: "mdi-arrow-right-bold-circle",
};
const size_units = ["cm","mm","in","px","pt","pc",];
const iconName = computed(() => iconMap[props.name ?? "default"] ?? props.name);
const color = computed(() => props.color);
const non_active = computed(() => !props.active && !props.hovered);
const size = computed(() => {
    if (!props.size) return null;

    return size_units.find(unit => props.size?.lastIndexOf(unit) === props.size?.length - 2)
        ? props.size
        : props.size + "px";
});
</script>
