import axios from "axios";
import { defineStore } from "pinia";
import { useConversation } from "./Conversation";
import { useWorkspace } from "./Workspaces";
import { useTeam } from "../Components/Custom/Team/Store/Team";
import Utilities from "../Helpers/utilities";
import { useUserStore } from "./User";

export const useInvitation = defineStore("InvitationStore", {
    state: () => ({
        show_invite_model: false,
        user_invitations: [],
        user_profile_ids: [],
        selected_user_profile_ids: [],
        // selected_group_ids: [],
        selected_group_user_profile_ids: [],
        is_retrieving_invited_user_in_progress: false,
        user_profiles_with_roles: [],
        has_conversation: false,
        RESOURCE_TYPES: {
            item: "items",
            "item-group": "item-groups",
            board: "boards",
            "board-group": "board-groups",
            workspace: "workspaces",
            "workspace-group": "workspace-groups",
        },
        // please don't change the order of the data
        ACCESS_LEVELS: {
            ITEM: "item",
            ITEM_GROUP: "item-group",
            BOARD: "board",
            BOARD_GROUP: "board-group",
            WORKSPACE: "workspace",
            WORKSPACE_GROUP: "workspace-group",
        },
        ROLES: {
            OWNER: "owner",
            ADMIN: "admin",
            MEMBER: "member",
            OBSERVER: "observer",
        },
        active_space_id: null,
        active_level_titles: {},
        current_participants: [],
        temp_roles_change_status: {},
        temp_chat_access_status: {},
        selected_ids_for_invite: new Set(),
        unselected_ids_for_remove: new Set(),
        current_user_ids: [],
    }),
    getters: {
        active_conversation_owner_id: (state) => {
            return useWorkspace().getOwnerIdForSpace(state.active_space_id);
        },
        new_modified_participants: (state) => {
            return (
                state.user_profiles_with_roles
                    .filter((user_profile) => {
                        return (
                            user_profile.is_conversation_participant ||
                            user_profile.role === state.ROLES.OBSERVER
                        );
                    })
                    .map((user_profile) => user_profile?.id) ?? []
            );
        },
        is_anyones_roles_changed: (state) => {
            return Object.values(state.temp_roles_change_status ?? {}).includes(
                true,
            );
        },
        is_anyones_chat_access_changed: (state) => {
            return Object.values(state.temp_chat_access_status ?? {}).includes(
                true,
            );
        },
        user_can_edit: (state) => {
            const user_profile = state.user_profiles_with_roles.find(
                (user) => user?.id === useUserStore().profile?.id,
            );
            if (!user_profile) return false;
            return [state.ROLES.OWNER, state.ROLES.ADMIN].includes(
                user_profile?.role,
            );
        },
    },
    actions: {
        closeInviteModel() {
            this.show_invite_model = false;
        },
        async getUserInvitations() {
            try {
                const { data } = await axios.get("/user/invitations");
                this.user_invitations = data?.data.lists;
            } catch (error) {
                console.log("error in console");
            }
        },
        resetInviteProfiles() {
            const all_profile_ids = this.selected_user_profile_ids.concat([
                ...new Set(this.selected_group_user_profile_ids),
            ]);
            this.user_profile_ids = [...new Set(all_profile_ids)];
        },
        restoreSelectedInviteProfiles() {
            this.selected_user_profile_ids = this.user_profile_ids;
        },
        clearSelectedInviteProfiles() {
            this.user_profile_ids = [];
            this.selected_user_profile_ids = [];
            this.selected_group_user_profile_ids = [];
        },
        setProfileIds(val) {
            this.user_profile_ids = val;
        },

        async getInvitedMembers(conversation) {
            const resource_type = this.RESOURCE_TYPES[conversation?.type];
            try {
                return await axios.get(
                    `/invites/chin/${resource_type}/${conversation?.type_id}`,
                );
            } catch (e) {
                console.log("getting invitation profile ids");
            }
        },
        async invitedProfiles() {
            this.is_retrieving_invited_user_in_progress = true;

            //reset previous profile ids if any
            this.setProfileIds([]);

            let invited_profile_ids = [];
            const conversation = useConversation().active;

            if (conversation?.is_item) {
                try {
                    const res = await this.getInvitedMembers(conversation);
                    const responseData = res?.data?.data;

                    const hasConversation = responseData.has_conversation;
                    const participants = responseData?.participants?.length;

                    if (hasConversation) {
                        invited_profile_ids =
                            responseData.invited_profile_ids || [];
                    } else if (participants) {
                        invited_profile_ids = responseData.participants;
                    } else if (responseData.all_profile_ids) {
                        invited_profile_ids = responseData.all_profile_ids;
                    }
                } catch (e) {
                    Utilities().showErrorSnackbar(
                        "Failed to retrieve existing invitation",
                    );
                    console.log(e);
                    return;
                }
            }

            if (conversation?.is_group || conversation?.is_group_one_off) {
                invited_profile_ids = conversation.getActiveParticipantIds();
            }

            if (!invited_profile_ids) {
                invited_profile_ids = [];
            }

            //invited_profile_ids.push(useWorkspace().getSpaceOwnerId())
            invited_profile_ids = Array.from(new Set(invited_profile_ids));

            this.setProfileIds(invited_profile_ids);

            this.restoreSelectedInviteProfiles();
            this.is_retrieving_invited_user_in_progress = false;
        },
        async fetchMembersWithRole(resource_type, resource_id) {
            try {
                this.temp_roles_change_status = {};
                this.temp_chat_access_status = {};

                useTeam().setAssigned(null);
                useTeam().setAssigner(null);
                useTeam().setOriginalAssigned(null);

                const res = await axios.get(
                    `invites/${resource_type}/${resource_id}`,
                );

                useTeam().setAssigned(
                    res?.data?.data?.assigned_user_profile_id,
                );
                useTeam().setAssigner(
                    res?.data?.data?.assigner_user_profile_id,
                );
                useTeam().setOriginalAssigned(
                    res?.data?.data?.assigned_user_profile_id,
                );
                this.has_conversation = res?.data?.data?.has_conversation;
                this.active_level_titles = res?.data?.data?.resource;
                this.user_profiles_with_roles =
                    res?.data?.data?.user_profiles?.map((user_profile) => {
                        const modified_user_profile = {
                            id: user_profile?.id,
                            role: user_profile?.role,
                            access_level: "",
                            is_conversation_participant: this.has_conversation
                                ? user_profile?.is_conversation_participant
                                : true,
                            has_parent_access: user_profile?.has_parent_access,
                        };

                        for (
                            let i = 0;
                            i < user_profile?.roles?.length ?? [].length;
                            i++
                        ) {
                            const access_level =
                                user_profile?.roles[i]?.model_type;

                            modified_user_profile.access_level = access_level;

                            const values = Object.values(this.ACCESS_LEVELS);
                            const access_level_index = values.findIndex(
                                (el) => el === access_level,
                            );

                            if (access_level_index === values.length - 1) {
                                break;
                            }
                        }

                        return modified_user_profile;
                    });

                this.current_participants =
                    res?.data?.data?.user_profiles
                        .filter(
                            (user_profile) =>
                                user_profile?.is_conversation_participant,
                        )
                        .map((user_profile) => user_profile?.id) ?? [];
                this.current_user_ids =
                    res?.data?.data?.user_profiles.map(
                        (user_profile) => user_profile?.id,
                    ) ?? [];

                this.selected_ids_for_invite.clear();
                this.unselected_ids_for_remove.clear();
            } catch (error) {
                console.log(error);
            }
        },
        updateRole(role, user_id) {
            const findedUser = this.user_profiles_with_roles?.find(
                (user_profile) => user_profile?.id === user_id,
            );
            if (findedUser) {
                findedUser.role = role;
            }
        },
        updateParticipantAccess(user_id, is_participant) {
            const findedUser = this.user_profiles_with_roles?.find(
                (user_profile) => user_profile?.id === user_id,
            );
            if (findedUser) {
                findedUser.is_conversation_participant = is_participant;
            }
        },
        addFakeMemberWithRoleIfNotExist(member, access_level) {
            const member_exist = this.user_profiles_with_roles
                ?.map((user_profile) => user_profile.id)
                ?.includes(member?.id);
            if (!member_exist) {
                const fake_profile = {
                    id: member?.id,
                    role: this.ROLES.MEMBER,
                    access_level,
                    is_conversation_participant: true,
                    has_parent_access: false,
                };
                if (member.non_registered) {
                    fake_profile.email = member.email;
                    fake_profile.non_registered = true;
                }
                this.user_profiles_with_roles.push(fake_profile);
            }
        },
        removeMemberWithRole(member_id) {
            this.user_profiles_with_roles =
                this.user_profiles_with_roles.filter((user_profile) => {
                    return user_profile?.id !== member_id;
                });
        },
        isUserParticipant(user_id) {
            return Boolean(
                this.user_profiles_with_roles.find(
                    (user) => user.id === user_id,
                ),
            );
        },

        canEditRoleBasedOnLevel(current_conv_type, user_highest_access) {
            const access_levels = Object.values(this.ACCESS_LEVELS ?? {});
            const conv_order_index = access_levels.findIndex(
                (access_level) => current_conv_type === access_level,
            );
            const user_access_order_idnex = access_levels.findIndex(
                (access_level) => user_highest_access === access_level,
            );
            return conv_order_index >= user_access_order_idnex;
        },
        updateUserAddRemoveTrackStatus(user_id, checked) {
            if (checked) {
                if (!this.current_user_ids.includes(user_id)) {
                    this.selected_ids_for_invite.add(user_id);
                } else {
                    this.unselected_ids_for_remove.delete(user_id);
                }
            } else {
                if (this.current_user_ids.includes(user_id)) {
                    this.unselected_ids_for_remove.add(user_id);
                } else {
                    this.selected_ids_for_invite.delete(user_id);
                }
            }
        },
        canShowItemIcon(type) {
            return [
                this.ACCESS_LEVELS.ITEM,
                this.ACCESS_LEVELS.ITEM_GROUP,
            ].includes(type);
        },

        canShowBoardIcon(type) {
            return [this.ACCESS_LEVELS.BOARD].includes(type);
        },

        canShowSpaceIcon(type) {
            return [
                this.ACCESS_LEVELS.WORKSPACE,
                this.ACCESS_LEVELS.WORKSPACE_GROUP,
                this.ACCESS_LEVELS.BOARD_GROUP,
            ].includes(type);
        },

        async unInviteMembers(
            resource_type,
            resource_id,
            user_ids,
            resource_name,
        ) {
            try {
                const resource = {
                    boards: "board",
                    workspaces: "space",
                    items: "item",
                    "item-groups": "item group",
                    "board-groups": "board group",
                    "workspace-groups": "space group",
                };
                const workspace_store = useWorkspace();
                await axios.post(`un-invites/${resource_type}/${resource_id}`, {
                    user_profile_ids: user_ids,
                });

                this.invitedProfiles();

                const { showInfoSnackbar } = Utilities();
                if (resource_name) {
                    const user = useUserStore().getUserProfile(user_ids[0]);
                    showInfoSnackbar(
                        `Your request for removing ${user.full_name} from the ${resource_name} ${resource[resource_type]} is being processed. Please note that this could take some time, depending on the number of items the user was a part of.`,
                    );
                }
                workspace_store.removeChinUsersBasedOnType(
                    resource_type,
                    user_ids,
                );
            } catch (error) {
                console.log(error);
            }
        },
    },
});
