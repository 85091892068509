import axios from "axios";
import { defineStore } from "pinia";
import { useAppState } from "../Stores/AppState";
import { useWorkspace } from "../Stores/Workspaces";

import TwilioFactory from "../Utilities/TwilioClientObserver";
import { useConversation } from "../Stores/Conversation";
import { cloneDeep } from "lodash";
import { useTeamPanel } from "./TeamPanel";
import { useUserDataKeyStore } from "./UserDataKey.js";
import Utilities from "Admin/js/Helpers/utilities.js";

export const useUserStore = defineStore("UserStore", {
    state: () => ({
        user: {
            id: "",
            name: "",
            email: "",
            country: "",
        },
        profile: {
            id: "",
            name: "",
            email: "",
        },
        user_data: null,
        newInvitedMember: false,
        newAssignMember: false,
        users: [],
        own_profiles: [],
        profiles: [],
        assigned_users: null,
        user_search_key: null,
        default_avatar: "/images/default-avatar.png",
        is_reinitialize_started: false,
        initiated_operations: [],
        completed_operations: [],
    }),
    getters: {
        is_client: (state) => state.profile?.type === "Client",
        is_not_client() {
            return !this.is_client;
        },
        getTwilioIdentity: (state) => state.user?.twilio_identity,
        is_personal: (state) =>
            state.user_data?.profile?.organization?.type === "personal" ||
            !state.user_data?.user?.signup_completed_at,
        is_org_creator: (state) => {
            return (
                state.profile?.organization?.creator_user_id === state.user?.id
            );
        },
        owner: (state) => {
            const owner_id = state.profile?.organization.creator_user_id;
            if (state.user?.id === owner_id) return state.user;
            return state?.users?.find((user) => user.id === owner_id);
        },
    },
    actions: {
        filterProfilesBy(search_key = "", profile_ids_at_start = []) {
            let members = this.profiles;

            if (search_key)
                members = members.filter(
                    (member) =>
                        member?.full_name
                            ?.toLowerCase()
                            .indexOf(search_key.toLowerCase()) > -1 ||
                        member?.email
                            ?.toLowerCase()
                            .indexOf(search_key.toLowerCase()) > -1,
                );

            if (!profile_ids_at_start?.length) return members;

            profile_ids_at_start
                .filter((profile_id) => parseInt(profile_id))
                ?.forEach((profile_id) => {
                    const workspace_onwer_index = members.findIndex(
                        (profile) => profile_id == profile.id,
                    );
                    if (workspace_onwer_index > 0) {
                        const owner_profile = members.splice(
                            workspace_onwer_index,
                            1,
                        )[0];
                        members.unshift(owner_profile);
                    }
                });

            return members;
        },
        setUserSearchKey(key = "") {
            this.user_search_key = key;
        },

        async initialize(is_reinitialize = false) {
            const app_state_store = useAppState();

            const userData = await axios.get(`/user`);
            const { profile, user, state } = userData?.data;

            useAppState().forceSignUpProcessIfNotCompleted(
                user?.signup_completed_at,
                user?.signup_index,
            );
            this.setUpUserData(user, profile);
            this.setUserData(userData.data);

            await useUserDataKeyStore().fetchUserDataKey(profile);

            //app_state_store.updateConversationState(state.is_conversation_open);
            Utilities().toggleChatPopoverWithoutUpdatingState(
                state.is_conversation_open,
            );

            const workspace_store = useWorkspace();
            const conversation_store = useConversation();
            app_state_store.togglePageLoader(false);
            if (state?.item_view_mode === "detail") {
                app_state_store.is_detail_mode = true;
            }
            Promise.all([
                axios.get(workspace_store.space_categories_url),
                axios.get("/conversations"),
            ])
                .then(async ([spacesData, convData]) => {
                    if (this.is_not_client) {
                        const conversations = convData.data?.conversations.map(
                            (conversation) => {
                                conversation.is_quick_load =
                                    state.conversation_id === conversation.id;
                                return conversation;
                            },
                        );

                        conversation_store.initialize(
                            conversations,
                            is_reinitialize,
                        );
                        conversation_store.loadGroups();
                    }
                    this.createTwilioClientIfUserAlreadyLoggedin();
                    this.setDefaultViewForClients();
                    conversation_store.loadConversationKaamfuRequests();
                    workspace_store.setSpaces(spacesData.data);
                    app_state_store.fetchLoggedTime();
                    await app_state_store.initializeWith(state);
                })
                .catch((error) => {
                    console.trace("Error on initialize");
                    if (error?.response?.status === 403)
                        app_state_store.setPermissionError(true);
                });
        },
        setUpUserData(user, profile) {
            this.user = { ...user, country: profile?.country_id };
            this.profile = profile;
            window.user = this.user;
            window.profile = this.profile;
            localStorage.setItem("kaamfu_user_id", this.user.id);

            window.registerForLiveSyncOnProfile(
                window.profile.id,
                window.profile.organization_id,
            );
        },

        setUpUserProfileData(profile) {
            this.profile = profile;
            window.profile = this.profile;
            localStorage.setItem("kaamfu_profile_id", this.profile.id);

            this.createTwilioClient();
        },
        setUpconversations(conversations) {
            initializeConversations();
        },
        createTwilioClient() {
            if (!this.profile.twilio_identity)
                return console.log("no twilio identity created");

            const { subscribeWithIdentity } = TwilioFactory();
            subscribeWithIdentity(this.profile.twilio_identity);
        },
        /* this is to pre initialize twilio client before loading any data
        as so speed up loading conversations */
        createTwilioClientIfUserAlreadyLoggedin() {
            const logged_in_user_id = localStorage.getItem("kaamfu_user_id");
            if (!logged_in_user_id) return;
            const { subscribeWithIdentity } = TwilioFactory();
            subscribeWithIdentity(logged_in_user_id);
        },
        async fetchUsers() {
            if (this.users?.length) return this.users;

            return axios.get("/users").then(({ data }) => {
                this.users = data;
                return data;
            });
        },
        async fetchUsersProfiles() {
            if (this.profiles?.length) return this.profiles;

            return axios.get("/user-profiles").then(({ data }) => {
                this.profiles = data;

                return data;
            });
        },
        setNewInvitedMember(val) {
            this.newAssignMember = false;
            this.newInvitedMember = val;
        },
        setNewAssignMember(val) {
            this.newInvitedMember = false;
            this.newAssignMember = val;
        },
        setAssignedUsers() {
            this.assigned_users = cloneDeep(this.users);
            this.assigned_users = this.assigned_users.map((user) => {
                if (this.isUserParticipant(user)) user.is_participant = true;
                return user;
            });
        },
        isUserParticipant(user) {
            const conversation_store = useConversation();
            const participants = conversation_store?.active?.participants;

            return (
                participants?.findIndex(
                    (participant) => participant.id == user.id,
                ) >= 0
            );
        },
        isCurrentProfile(profile_id) {
            return profile_id ? this.profile.id === profile_id : false;
        },
        isAuthorizedFor(permission) {
            return Boolean(this.profile?.permissions?.[permission]);
        },
        hasPermissionForScreenshots(user_profile_id) {
            return this.profile?.permissions?.screenshots?.includes(
                user_profile_id,
            );
        },
        hasOnlySubordinateTimerAQEvents(user_profile_id) {
            return this.profile?.permissions?.immediate_subordinate_aq?.includes(
                user_profile_id,
            );
        },
        getUserProfile(profile_id) {
            return this.profiles?.find((profile) => profile.id == profile_id);
        },
        getUserProfileByTwilioId(twilio_identity) {
            return this.profiles?.find(
                (profile) => profile.twilio_identity == twilio_identity,
            );
        },
        getCurrentUserProfile() {
            return this.profile;
        },
        getCurrentUserProfileId() {
            return this.profile?.id;
        },
        getCurrentUserOrganizationId() {
            return this.profile.organization_id;
        },
        userProfileSetActiveTask(profile_id, status) {
            return;
        },
        updateAccount() {
            axios.patch("/user", {
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                country_id: this.user?.country,
            });
        },
        updateProfile(profile) {
            axios
                .patch(`/profiles/${profile.id}`, profile)
                .then(({ data }) => {});
        },
        updateProfileAvatar(profileId, base64) {
            axios
                .post(`/profiles/${profileId}/avatar`, { avatar: base64 })
                .then(({ data }) => {
                    if (this.profile.id === profileId) {
                        this.profile.avatar_url = data.data.url;
                    }
                });
        },
        setUserData(val) {
            this.user_data = val;
            this.own_profiles = val.profiles;

            if (val.profile.type === "personal")
                useAppState().setControlAsSelectedModule(true);
        },

        async getProfileById(profile_id) {
            try {
                const { data } = await axios.get(`user-profiles/${profile_id}`);
                return data.data;
            } catch (error) {
                console.error("getProfileById failed", error);
                return {};
            }
        },
        async replaceProfile(new_profile) {
            //const profile_index = this.profiles.findIndex(profile => profile.id === new_profile.id)
            const profile_index = this.profiles.findIndex(
                (profile) => profile.email === new_profile.email,
            );
            const profile = await this.getProfileById(new_profile.id);
            profile.is_login = 0;
            if (this.isCurrentProfile(profile.id)) profile.is_login = 1;

            if (profile_index >= 0) {
                // merge and replace profile.

                const img = new Image();
                const img_url = profile.avatar_url ?? new_profile.avatar_url;
                img.onload = () => {
                    this.profiles[profile_index].avatar_url = img_url;
                };
                img.src = img_url;

                delete profile["avatar_url"];

                this.profiles.splice(profile_index, 1, {
                    ...this.profiles[profile_index],
                    ...profile,
                });
            }
        },
        async replaceCurrentProfile(new_profile) {
            this.profile = { ...this.profile, ...new_profile };
        },

        async addCompletedOperation(completed_operation) {
            const itemIndex = this.initiated_operations.findIndex(
                (item) => item.id === completed_operation.id,
            );
            if (itemIndex !== -1) {
                this.initiated_operations.splice(itemIndex, 1);
            }

            this.completed_operations.push(completed_operation);
        },

        async addInitiatedOperation(initiated_operation) {
            this.initiated_operations.push(initiated_operation);
        },

        addProfile(new_profile) {
            const profile_index = this.profiles.findIndex(
                (profile) => profile.email === new_profile.email,
            );
            if (profile_index !== -1) {
                this.profiles.splice(profile_index, 1, new_profile);
            } else {
                this.profiles.push(new_profile);
            }
        },
        updateProfileTask(item) {
            const active_task_profile = this.profiles.find(
                (profile) => profile?.active_task?.id === item.id,
            );
            if (active_task_profile)
                active_task_profile.active_task.name = item.name;

            const paused_task_profile = this.profiles.find(
                (profile) => profile.paused_task?.id === item.id,
            );
            if (paused_task_profile)
                paused_task_profile.paused_task.name = item.name;
        },
        setDefaultViewForClients() {
            if (this.is_not_client) return;

            const workspace_store = useWorkspace();
            workspace_store.setFullMode();
        },

        isCurrentUser(twilio_identity) {
            return this.user.twilio_identity === twilio_identity;
        },

        isNotCurrentUser(twilio_identity) {
            return this.user.twilio_identity !== twilio_identity;
        },

        async reInitialize() {
            if (this.is_reinitialize_started) {
                return;
            }
            try {
                this.is_reinitialize_started = true;
                console.log("ReInitialize started at", Date().toString());
                this.initialize(true);
                // Team Panel start
                if (useTeamPanel().is_prepared) {
                    await useTeamPanel().reInitializeTeamPanel();
                }
                console.log("ReInitialize ended at", Date().toString());
                this.is_reinitialize_started = false;
                // Team Panel end
            } catch (e) {
                console.log("ReInitialize failed", e, Date().toString());
                this.is_reinitialize_started = false;
            }
        },

        increaseTwilioConversationCount() {
            this.profile.twilio_conversations_count += 1;
        },
        setTwilioConversationCount(count) {
            this.profile.twilio_conversations_count = count;
        },
        decreaseTwilioConversationCount() {
            this.profile.twilio_conversations_count -= 1;
        },
        canInviteToOrganization() {
            return this.getCurrentUserProfile().type === "business";
        },
        async loadUserState() {
            const res = await axios.get(`/user`);
            this.setUserData(res.data);
            useAppState().forceSignUpProcessIfNotCompleted(
                res.data?.user?.signup_completed_at,
            );
        },
        setResumeTime(resume_at) {
            this.profile.shift_resume_at = resume_at;
        },
        canShowAsigner(user_profile) {
            if (!user_profile || !this.profile?.organization?.creator_user_id) {
                return false;
            }

            if (this.profile?.organization?.creator_user_id === this.user?.id) {
                return user_profile.email !== this.user.email;
            }

            const subordinates = useTeamPanel().getSubordinatesByProfile(
                this.profile.id,
            );
            const user = subordinates.find(
                (user) => user.user_profile_id === user_profile.user_profile_id,
            );
            return !!user;
        },
        async enableAdminSupport() {
            try {
                await axios.post("/organizations/invite/support-admin");
            } catch (error) {}
        },
        async disableAdminSupport() {
            try {
                await axios.post("/organizations/un-invite/support-admin");
                this.user_data.support_admin = null;
            } catch (error) {}
        },

        getProfilesByIds(profile_ids = []) {
            let members = this.profiles;

            if (!profile_ids?.length) return [];

            return members.filter((profile) => profile_ids.includes(profile.id));
        }
    },
});
