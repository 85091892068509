import axios from "axios";
import { defineStore } from "pinia";
import { useConversation } from "./Conversation";
import { useWorkspace } from "./Workspaces";
import {
    DEFAULT_MODULE,
    DEFAULT_PAGE,
    getModuleByName,
    MAIN_MODULES,
} from "../Constants/menus";
import Cache from "../Utilities/Cache";
import { useUserStore } from "./User";
import { useTimeTrackerStore } from "./TimeTracker";
import Utilities from "../Helpers/utilities";
import HttpStatusCodes from "../Constants/http";

export const useAppState = defineStore("AppStateStore", {
    state: () => ({
        user_id: null,
        workspace_id: null,
        board_id: null,
        item_id: null,
        document_id: null,
        active_panel: "",
        // is_team_opened:false,
        conversation_id: null,
        is_conversation_open: null,

        spaces_panel_view: "kanban",
        boards_panel_view: "kanban",
        items_panel_view: "kanban",

        selected_variant_tab: "all",
        previous_selected_variant_tab: "all",
        locked_selected_variant_tab: null,

        is_document_kanban_view: null,
        is_document_opened_in_activity: false,

        updateStateThrottler: null,
        is_state_restoring_in_progress: 0,

        is_manage_organization_role_opened: false,
        role_object: {},

        invite_drawer: {
            visible: false,
            data: {},
            type: "item",
            new_group: false,
            is_team_opened: false,
            is_productivity_opened: false,
        },

        productivity_drawer: {
            visible: false,
            data: {},
            type: "item",
            is_productivity_opened: false,
        },

        prospus_mouse_track_y: 0,
        prospus_mouse_track_x: 0,
        prospus_mouse_track: false,
        show_floating_buttons: true,
        user_is_typing_on_chat: false,
        page_loader: true,
        conv_loader: true,
        activity_details: false,
        selected_module: null,
        active_page: DEFAULT_PAGE,
        main_modules: MAIN_MODULES,
        activity_pinned_list: [],
        is_permission_error: false,
        usr_profile_logged_time: [],
        handle_fetch_logged_in_time_interval: null,
        is_in_active_window: null,
        is_internet_connected: true,
        team_filter: null,
        team: {
            selected_user_id: null,
            assigner_id: null,
            is_subordinate_panel: false,
            selected_subordinate_id: null,
        },
        countries: [],
        services: [],
        productive: {
            selected_user_id: null,
            selected_tab: null,
            productivity_opened: false,
            filter: {
                by_chronological: true,
                by_space: false,
                last_7_days: true,
                last_30_days: false,
                today: false,
                custom_dates: null,
            },
        },
        live_sync: {
            is_disconnected: false,
            connected_at: null,
            disconnected_at: null,
            connected_count: 0,
        },
        app_focus: {
            focus_in_at: null,
            focus_out_at: null,
        },
        saved_state: null,
        show_control_panel: false,
        show_control_panel_active_index: 0,
        selection_histories: [],
        selection_history_index: 0,
        is_back_clicked: false,
        notification_confirm_dialog: true,
        skip_update_state: false,
        is_log_enable: false,
        in_between_restoring_status: null,
        has_opened_context_menu: false,
        message_input_height: null,
        is_agent: true,
        is_detail_mode: false,
        is_panel_loaded: false,
        is_dashboard_mode: false,
    }),
    getters: {
        getModuleWork: (state) =>
            state.main_modules.find((module) => module.name === "work"),
        getModuleActivity: (state) =>
            state.main_modules.find((module) => module.name === "activity"),
        getModuleTeam: (state) =>
            state.main_modules.find((module) => module.name === "team"),
        is_group_locked_variant_tab: (state) =>
            state.locked_selected_variant_tab === "group",
        is_group_selected_variant_tab: (state) =>
            state.selected_variant_tab === "group",
        is_invite_selected_variant_tab: (state) =>
            state.selected_variant_tab === "invites",
        is_activity: (state) => state.active_page == "activity",
        is_team: (state) => state.active_page == "team",
        is_work: (state) => state.active_page == "work",
        is_productive_panel_open: (state) =>
            state.productivity_drawer?.is_productivity_opened,
        selection_history: (state) => state.selection_histories,
        is_internet_disconnected: (state) => !state.is_internet_connected,
        is_logging_started: (state) => state.is_log_enable,
    },
    actions: {
        setRoleObject(roleObject = {}) {
            this.role_object = roleObject;
        },

        disconnected() {
            this.live_sync.is_disconnected = true;
            this.live_sync.disconnected_at = Date.now();
        },
        connected() {
            this.live_sync.is_disconnected = false;
            this.live_sync.connected_at = Date.now();
            this.live_sync.connected_count = this.live_sync.connected_count + 1;
        },
        togglePageLoader(value = null) {
            if (value === null) {
                return (this.page_loader = !this.page_loader);
            }

            this.page_loader = value;
        },
        setSelectedVariantTab(value) {
            this.selected_variant_tab = value;
        },
        setPreviousSelectedVariantTab(value) {
            this.previous_selected_variant_tab = value;
        },
        resetSelectedVariantTab() {
            this.selected_variant_tab = "all";
        },
        handleMembershipPanel() {
            if (this.previous_selected_variant_tab !== "members") {
                this.previous_selected_variant_tab = "all";
                this.selected_variant_tab = "all";
            } else if (this.invite_drawer.is_team_opened) {
                this.selected_variant_tab = "all";
            } else {
                this.selected_variant_tab = this.previous_selected_variant_tab;
            }
        },
        setLockedSelectedVariantTab(value) {
            this.locked_selected_variant_tab = value;
        },
        setActiveMouseTrack(value) {
            this.prospus_mouse_track = value;
        },
        setActiveFloatingBtns(value) {
            this.show_floating_buttons = value;
        },
        setActiveUserTyping(value) {
            this.user_is_typing_on_chat = value;
        },
        setMouseYpath(value) {
            this.prospus_mouse_track_y = value;
        },
        setInActiveWindow(value) {
            this.is_in_active_window = value;

            if (value) {
                this.app_focus.focus_out_at = Date.now();
            } else {
                this.app_focus.focus_in_at = Date.now();
            }

            // reload if app focus after 30 minutes
            if (
                this.app_focus.focus_out_at &&
                this.app_focus.focus_in_at &&
                this.app_focus.focus_in_at - this.app_focus.focus_out_at >
                    30 * 60 * 1000
            ) {
                // if focus in after 30 minutes app will reload.
                window.location.reload();
            }
        },
        async setInternetConnectivity(value) {
            try {
                await axios.get(`online-status`);
                this.is_internet_connected = true;
                useConversation().loadConversationKaamfuRequests();
            } catch (error) {
                const offline_error_codes = [
                    "ERR_NETWORK", // No network connection
                    "ECONNABORTED", // Connection timed out
                    "ERR_CONNECTION_REFUSED", // Connection refused,
                    "ENOTFOUND", // Domain not found
                ];

                if (offline_error_codes.includes(error.code)) {
                    this.is_internet_connected = false;
                    console.log("api", this.is_internet_connected);
                }
            }
        },
        setLoggingOn(value) {
            this.is_log_enable = value;
        },
        toggleNewGroupInvite(val) {
            this.invite_drawer.new_group = val ?? !this.invite_drawer.new_group;
        },

        toggleInviteDrawer(visible, objData = {}, is_team_opened) {
            const type = objData?.type ?? useWorkspace().invite_type;
            this.invite_drawer.visible = visible ?? !this.invite_drawer.visible;
            this.invite_drawer.data = objData;
            this.invite_drawer.type = type;
            this.invite_drawer.is_team_opened = is_team_opened ?? false;
        },

        toggleProductivityDrawer(val, is_update_state = true) {
            this.productivity_drawer.is_productivity_opened = val ?? false;

            if (!is_update_state) return;

            const productive = this.productive;
            if (productive)
                productive.productivity_opened =
                    this.productivity_drawer.is_productivity_opened;

            this.updateProductiveState(productive);
        },

        setControlAsSelectedModule(val) {
            this.show_control_panel = val;
        },
        setStoreAsSelectedModule() {
            this.selected_module = this.main_modules.find(
                (module) => module.name === "store",
            );
        },
        setWorkAsSelectedModule() {
            const module = this.main_modules.find(
                (module) => module.name === "work",
            );
            this.setSelectedModule(module);
        },
        setActivityAsSelectedModule() {
            const module = this.main_modules.find(
                (module) => module.name === "activity",
            );
            this.setSelectedModule(module);
        },
        setTeamAsSelectedModule() {
            const module = this.main_modules.find(
                (module) => module.name === "team",
            );
            this.setSelectedModule(module);
        },
        setGoalsAsSelectedModule() {
            this.selected_module = this.main_modules.find(
                (module) => module.name === "goals",
            );
        },
        setPlannerAsSelectedModule() {
            this.selected_module = this.main_modules.find(
                (module) => module.name === "planner",
            );
        },

        updateSelectedModule(name) {
            this.selected_module = getModuleByName(name);

            this.active_page = name;
        },

        setSelectedModule(data, is_restore = false) {
            if (!data) return;
            if (data.name === "team") {
                useWorkspace().setActiveItemEmpty();
            }

            this.updateSelectedModule(data.name);

            const cache = new Cache();
            cache.set("active_page", this.active_page);

            setTimeout(() => {
                this.restoreActivityState();
                if (is_restore) {
                    useWorkspace().restoreEditorState(
                        this.document_id,
                        this.item_id,
                    );
                }
            }, 100);

            if (!is_restore) {
                useWorkspace().closeEditor();
                this.is_document_opened_in_activity = false;
            }

            this.updateState();
        },
        setDrawerType(type) {
            this.invite_drawer.type = type;
        },
        openDocumentInActivity() {
            this.is_document_opened_in_activity = true;
            this.updateState();
        },
        closeDocumentInActivity() {
            this.is_document_opened_in_activity = false;
            this.updateState();
        },
        updateDocumentStateInActivity(status) {
            this.is_document_opened_in_activity = status;
            this.updateState();
        },
        toggleActivityDetails(is_visible = true) {
            this.activity_details = is_visible;
            this.updateState();
        },
        initializeWith(data) {
            this.startRestoringState();
            this.selected_module = DEFAULT_MODULE;

            //console.log("user -state", data)
            this.$patch(data);
            //console.log("after setting", this.workspace_id);
            this.restoreState();
        },
        startRestoringState() {
            this.is_state_restoring_in_progress += 1;
        },
        stopRestoringState() {
            this.is_state_restoring_in_progress -= 1;

            /* if (this.in_between_restoring_status) {
                this.save(this.in_between_restoring_status);
            }*/
        },
        setPinnedItems(value) {
            this.activity_pinned_list = value;
        },
        restoreState() {
            this.restoreSpaceState();
            this.restoreConversationState();

            timeOutHelper(() => {
                this.restoreActivityState();
            }, 100);
        },
        setPinnedList(activity_pinned_items) {
            const activity_pinned_list = activity_pinned_items.map((str) => {
                return Number(str);
            });

            this.setPinnedItems([...new Set(activity_pinned_list)]);
        },
        async restoreSpaceState(state = null) {
            const is_activity = DEFAULT_MODULE.name == "activity";
            if (!this.workspace_id && !is_activity)
                return this.stopRestoringState();

            //load workspace boards
            const workspace_store = useWorkspace();
            const conversation_store = useConversation();
            try {
                if (state === null) {
                    const { data } = await axios.get(
                        "/user-states?load-space-board=1",
                    );
                    state = data;
                }

                this.restoreProductivePanel(state.productive);
                this.setPinnedList(state.activity_pinned_list);

                this.updateSelectedItemId(state.item_id);
                this.updateEditor(state.document_id);
                this.updateConversationId(state.conversation_id);
                this.updateConversationState(state.is_conversation_open);
                Utilities().toggleChatPopoverWithoutUpdatingState(
                    state.is_conversation_open,
                );
                this.restoreSelectionHistory(state?.histories);
                conversation_store.restoreRecentlyOpenedConversations(
                    state?.recently_opened_conversations,
                );

                this.updateDocumentStateInActivity(
                    state.is_document_opened_in_activity,
                );
                workspace_store.loadLastOpenedSpaceAndBoard(state);
                workspace_store.restoreActivePanel(this.active_panel);
                workspace_store.restoreEditorState(
                    this.document_id,
                    this.item_id,
                );

                const active_page = state.active_page;

                if (active_page)
                    this.setSelectedModule(getModuleByName(active_page), true);
                else this.setSelectedModule(DEFAULT_MODULE, true);

                if (this.is_team) {
                    this.closeDocumentInActivity(false);
                    useWorkspace().setEditorView(false);
                    useWorkspace().closeEditor();
                }

                this.stopRestoringState();
            } catch (error) {
                if (error.response?.status === HttpStatusCodes.FORBIDDEN)
                    this.setPermissionError(true);

                this.stopRestoringState();
            }
        },
        restoreConversationState() {
            this.startRestoringState();
            const conversation_store = useConversation();

            if (!this.productive?.productivity_opened)
                conversation_store.restore(
                    this.conversation_id,
                    this.is_conversation_open,
                );

            this.stopRestoringState();
        },
        updateState() {
            const state = this.getCurrentUserState();
            if (this.is_state_restoring_in_progress) {
                this.in_between_restoring_status = state;
                return;
            }

            this.save(state);
        },
        save(state) {
            if (this.is_state_restoring_in_progress) {
                this.in_between_restoring_status = state;
                return;
            }
            if (this.is_detail_mode) {
                state.item_view_mode = "detail";
            }
            if (
                (this.saved_state &&
                    JSON.stringify(this.saved_state) ===
                        JSON.stringify(state)) ||
                this.skip_update_state
            ) {
                // data is same. therefore, api call is not required.
                return;
            }

            axios.patch("/user-states", state);
            this.saved_state = state;
        },
        getCurrentUserState() {
            return {
                workspace_id: this.workspace_id,
                board_id: this.board_id,
                item_id: this.item_id,
                document_id: this.document_id,
                conversation_id: this.conversation_id,
                is_conversation_open: this.is_conversation_open,
                is_document_kanban_view: this.is_document_kanban_view,
                active_panel: this.active_panel,
                active_page: this.active_page,
                is_document_opened_in_activity:
                    this.is_document_opened_in_activity,
                team_filter: this.team_filter,
                team: this.team,
                productive: this.productive,
            };
        },
        updateWorkspaceId(id) {
            this.workspace_id = id;
            this.board_id = null;
            this.updateState();
        },
        updateBoardId(id) {
            this.board_id = id;
            this.updateState();
        },
        updateSelectedItemId(item_id) {
            this.item_id = item_id;
            this.addItemInSelectionHistory();
            this.updateState();
        },
        updateConversationId(id) {
            if (!id)
                return console.log(`Invalid converation for state restroing`);
            this.conversation_id = id;
            this.updateState();
        },
        updateConversationState(is_opened) {
            this.is_conversation_open = is_opened;
            this.updateState();
        },
        restoreSelectionHistory(histories) {
            this.selection_histories = histories;
        },
        updateEditor(id) {
            if (this.is_state_restoring_in_progress) return;

            this.document_id = id;
            this.updateState();
        },
        updateActivePanel(panel_view) {
            if (this.is_state_restoring_in_progress) return;

            this.active_panel = panel_view;
            this.updateState();
        },
        updateTeamFilter(filter) {
            this.team_filter = filter;
            this.updateState();
        },
        updateProductiveState(productive_state) {
            this.productive = productive_state;
            this.updateState();
        },

        updateTeam(value) {
            if (
                value["selected_user_id"] === this.team["selected_user_id"] &&
                value["is_subordinate_panel"] ===
                    this.team["is_subordinate_panel"] &&
                value["selected_subordinate_id"] ===
                    this.team["selected_subordinate_id"] &&
                value["assigner_id"] === this.team["assigner_id"]
            )
                return;

            this.team = value;
            this.updateState();
        },
        async restoreActivityState() {
            if (!this.is_activity) return;

            const active_conv = useConversation().active;

            if (!active_conv?.id || !this.is_conversation_open) return;

            useConversation().setDocumentByConversationId(active_conv?.id);

            if (!this.is_document_opened_in_activity) {
                return this.toggleActivityDetails(false);
                useWorkspace().closeEditor();
                return;
            }

            useWorkspace().setActiveChatData(active_conv);
            this.toggleActivityDetails(true);
        },
        updateSelectionHistoryByItem(item) {
            this.selection_histories = this.selection_histories.map((data) => {
                if (data.type === item.type && item.id === data.id) {
                    data.name = item.name;
                }
                return data;
            });
        },
        setBrowserHistory() {
            const url = new URL(window.location.href);
            if (!url.pathname.replace(/^\/+|\/+$/g, "")) return;

            const new_url = new URL(url.protocol + "//" + url.hostname);
            history.replaceState({}, "", new_url.toString());
        },
        setPermissionError(value) {
            this.is_permission_error = value;
        },
        async fetchLoggedTime() {
            // it can be improved in future and only call if loggedin user has subordinates
            const { data } = await axios.get("/shift-timer/shift");
            this.usr_profile_logged_time = data.data;

            this.startShiftFetchingTimer();
        },
        startShiftFetchingTimer() {
            if (this.handle_fetch_logged_in_time_interval) return;

            this.handle_fetch_logged_in_time_interval = setInterval(() => {
                axios.get("/shift-timer/shift").then(({ data }) => {
                    this.usr_profile_logged_time = data.data;
                });
            }, 60000);
        },
        stopShiftFetchingTimer() {
            clearInterval(this.handle_fetch_logged_in_time_interval);
        },
        getShiftInUsers() {
            const task_start = useTimeTrackerStore().Task_Start;
            const task_stop = useTimeTrackerStore().Task_Stop;
            const task_pause = useTimeTrackerStore().Task_Pause;
            let profiles = [];
            this.usr_profile_logged_time.forEach((shift_in_user) => {
                const profile = useUserStore().getUserProfile(
                    shift_in_user.user_profile_id,
                );
                if (profile) {
                    //   profile.timer = convertMinutesToHoursAndMinutes(shift_in_user.total_minutes);
                    //   profile.progress = calculateDayProgress(shift_in_user.total_minutes);

                    /*
                         no badge = no task selected.
                         green = task selected,
                         gray = task paused / shift paused
                         red = blocked, waiting for something. ( implementation not done yet)

                         */
                    const user_profile_id =
                        shift_in_user?.active_task?.version
                            ?.user_profile_id_creator ||
                        shift_in_user?.paused_task?.version
                            ?.user_profile_id_creator;

                    profile.active_task = shift_in_user.active_task;
                    profile.paused_task = shift_in_user.paused_task;
                    profile.task_status = task_stop;
                    profile.shift_status = shift_in_user?.event_type;
                    profile.is_team = shift_in_user.is_team;

                    profile.assigner_info = user_profile_id
                        ? useUserStore().getUserProfile(user_profile_id)
                        : user_profile_id;
                    if (
                        this.hasActiveTask(profile) &&
                        useTimeTrackerStore().isShiftRunning(
                            shift_in_user?.event_type,
                        )
                    )
                        profile.task_status = task_start;
                    else if (
                        useTimeTrackerStore().isShiftPauseOrUnreachableOrInActive(
                            shift_in_user?.event_type,
                        )
                    )
                        profile.task_status = task_pause;

                    profiles.push(profile);
                }
            });
            return profiles;
        },
        getShiftInUsersForTeamStatus() {
            return this.getShiftInUsers().filter(
                (profile) => !useUserStore().isCurrentProfile(profile.id),
            );
        },

        getShiftInUsersForTeamStatusByUserId(user_id) {
            return this.getShiftInUsers().filter(
                (profile) => profile.id != user_id,
            );
        },

        hasActiveTask(profile) {
            return profile?.active_task;
        },
        restoreProductivePanel(productive, force_open = false) {
            this.productive = productive;
            const is_open = productive?.productivity_opened || force_open;
            this.toggleProductivityDrawer(is_open, false);
            if (is_open) Utilities()?.toggleChatPopover(is_open);
        },
        async handleSharedLink(url) {
            const token = new URL(url).pathname.replace(/^\/+|\/+$/g, "");
            if (token) {
                try {
                    const { data } = await axios.get(
                        `/user-states?load-space-board=1&link-token=${token}`,
                    );
                    this.restoreSpaceState(data);
                } catch (e) {
                    console.debug("Failed to restore link state");
                }
            }
        },
        addItemInSelectionHistory() {
            const active_item = useWorkspace().active_item;
            if (
                !active_item ||
                !active_item?.id ||
                this.is_state_restoring_in_progress
            ) {
                return;
            }

            const index = this.selection_histories.findIndex(
                (history) => history.id == active_item.id,
            );

            if (index === 0) {
                return;
            }

            if (index > -1) {
                this.selection_histories.splice(index, 1);
            } else if (!this.is_back_clicked) {
                this.selection_history_index = 0;
            }

            console.log("added to history", active_item.name);

            const workspace_avatar = useWorkspace().getSpaceBoardForItem(
                active_item.id,
            )?.space?.avatar_url;

            this.selection_histories.splice(0, 0, {
                id: active_item.id,
                name: active_item.name,
                type: active_item?.type,
                avatar_url: workspace_avatar ?? null,
            });
            this.is_back_clicked = false;
        },
        setHistorySelectionIndexOnClickBack() {
            const history_length = this.selection_histories.length;
            this.selection_history_index += 1;
            if (this.selection_history_index >= history_length) {
                this.selection_history_index = 0;
            }
            return this.selection_history_index;
        },
        loadCountries() {
            return axios.get(`/countries`).then((res) => {
                //user_data.value = res.data
                this.countries = res.data.data.lists;
            });
        },
        loadServices() {
            return axios.get(`/services`).then((res) => {
                //user_data.value = res.data
                this.services = res.data.data.lists;
            });
        },
        forceSignUpProcessIfNotCompleted(is_sign_up_complete = null) {
            this.show_control_panel = !Boolean(is_sign_up_complete);
            this.setControlAsSelectedModule(!Boolean(is_sign_up_complete));
            if (is_sign_up_complete) {
                /* Utilities().toggleChatPopoverWithoutUpdatingState(
                    this.is_conversation_open,
                );*/
            } else {
                this.show_control_panel_active_index = 1;
            }
        },

        setIsAgent(value) {
            this.is_agent = value;
        },

        skipUpdateState() {
            this.skip_update_state = true;
        },

        continueUpdateState() {
            this.skip_update_state = false;
        },
        setIsdetailMode() {
            this.is_detail_mode = !this.is_detail_mode;
            this.is_dashboard_mode = false;
            const data = {
                item_view_mode: this.is_detail_mode ? "detail" : "normal",
            };
            axios.patch("/user-states", data);
        },
        toggleDashBoardOrNormalMode() {
            this.is_dashboard_mode = !this.is_dashboard_mode;
        },
    },
});
